@import "/public/assets/style/variables";

.dashboard-page-card {
  background: #483e97;
  background-image: url(http://restream.ibox4u.com/AdminLogin/assets/images/pattern.png);
  background-size: cover;
  border-radius: 10px;
  box-shadow: 4px 4px 12px rgba(0,0,0,.11);
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 130px;
  justify-content: space-around;
  margin: 5px;
  opacity: 1;
  padding: 10px 24px;
  position: relative;
  width: 330px;
  font-size: 1rem;
}
